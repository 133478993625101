import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AiOutlinePieChart,
  AiOutlineTeam,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { RiDashboardLine } from "react-icons/ri";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { GoReport } from "react-icons/go";
import { HiOutlineCollection, HiOutlineBell } from "react-icons/hi";
import { Link, NavLink, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { baseUrlUser } from "../../config/baseUrl";
import useUserStore from "../../store/userStore";
import axios from "../../../PageResponsibility/src/admin/config/axiosAuth";
import useSidebarStore from "../../store/sidebarStore";
import "./sidebar.scss";
import { getCurrentLocation } from "../../../utils/transformData";
import LogoutAlert from "../../../PageResponsibility/src/admin/components/ui/LogoutAlert";
import { GiTeamIdea } from "react-icons/gi";

const userSidebarData = [
  {
    id: 1,
    label: "Dashboard",
    icon: <RiDashboardLine />,
    path: "/userdashboard",
  },
  {
    id: 2,
    label: "Projects",
    icon: <AiOutlinePieChart />,
    path: "/userProjects",
  },
  {
    id: 3,
    label: "Task Management ",
    icon: <AiOutlinePieChart />,
    path: "/userTaskmanagement",
  },
  {
    id: 4,
    label: "Report",
    icon: <GoReport />,
    path: "/userReports",
  },
  {
    id: 5,
    label: "Notification",
    icon: <HiOutlineBell />,
    path: "/userNotification",
  },
  {
    id: 6,
    label: "Leaves",
    icon: <AiOutlineUsergroupAdd />,
    path: "/userLeaves",
  },
  {
    id: 7,
    label: "Meetings",
    icon: <AiOutlineUsergroupAdd />,
    path: "/userMeetings",
  },
  {
    id: 8,
    label: "Attendance",
    icon: <AiOutlineUsergroupAdd />,
    path: "/userAttendance",
  },
  {
    id: 9,
    label: "Detailed Timesheet",
    icon: <HiOutlineCollection />,
    path: "/userDetailedTimesheet",
  },
];

const responsibilitySideBar = [
  {
    id: 10,
    label: "Dashboard",
    icon: <RiDashboardLine />,
    path: "/dashboard",
    responsibility: "Dashboards",
    givenResponsibilities: [],
  },
  {
    id: 11,
    label: "Client",
    icon: <AiOutlineTeam />,
    path: "/clients",
    responsibility: "Clients",
    givenResponsibilities: [],
  },
  {
    id: 12,
    label: "Detailed Time Sheet",
    icon: <HiOutlineCollection />,
    path: "/detailedTimesheet",
    responsibility: "Detailed timeSheet",
    givenResponsibilities: [],
  },
  {
    id: 13,
    label: "Projects",
    icon: <AiOutlinePieChart />,
    path: "/Project",
    responsibility: "Projects",
    givenResponsibilities: [],
  },
  {
    id: 14,
    label: "Team",
    icon: <AiOutlineUsergroupAdd />,
    path: "/team",
    responsibility: "Teams",
    givenResponsibilities: [],
  },
  {
    id: 25,
    label: "Allocations ",
    icon: <GiTeamIdea  />,
    path: "/allocations", responsibility: "Allocations",
    givenResponsibilities: [],
  },
  {
    id: 15,
    label: "Tester",
    icon: <AiOutlineUsergroupAdd />,
    path: "/testing",
    responsibility: "Testers",
    givenResponsibilities: [],
  },
  {
    id: 16,
    label: "Leads",
    icon: <AiOutlineUsergroupAdd />,
    path: "/leads",
    responsibility: "Leads",
    givenResponsibilities: [],
  },
  {
    id: 17,
    label: "Leaves",
    icon: <AiOutlineUsergroupAdd />,
    path: "/leave",
    responsibility: "Leaves",
    givenResponsibilities: [],
  },
  {
    id: 18,
    label: "Attendance",
    icon: <AiOutlineUsergroupAdd />,
    path: "/attendance",
    responsibility: "Attendances",
    givenResponsibilities: [],
  },
  {
    id: 19,
    label: "Report",
    icon: <GoReport />,
    path: "/adminReport",
    responsibility: "Reports",
    givenResponsibilities: [],
  },
  {
    id: 20,
    label: "Hiring",
    icon: <AiOutlineUsergroupAdd />,
    path: "/newHiring",
    responsibility: "Hirings",
    givenResponsibilities: [],
  },
  {
    id: 21,
    label: "Hiring Follow Ups",
    icon: <AiOutlineUsergroupAdd />,
    path: "/hiring-followups",
    responsibility: "Followups",
    givenResponsibilities: [],
  },
  {
    id: 22,
    label: "Task Management ",
    icon: <AiOutlinePieChart />,
    path: "/taskmanagement",
    responsibility: "Tasks",
    givenResponsibilities: [],
  },

  {
    id: 23,
    label: "Meetings",
    icon: <AiOutlineUsergroupAdd />,
    path: "/meetings",
    responsibility: "Meetings",
    givenResponsibilities: [],
  },

  {
    id: 24,
    label: "PayRoll",
    icon: <AiOutlineTeam />,
    path: "/Payroll",
    responsibility: "Payrolls",
    givenResponsibilities: [],
  },
];

function UserSidebar() {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [openCollapse, setOpenCollapse] = useState({ notification: false });
  const [activeSideBar, setActiveSideBar] = useState("user");
  const [sideBar, setSideBar] = useState(userSidebarData);
  const { setResponsibilitySidebar } = useSidebarStore();
  useEffect(() => {
    const storedActiveSidebar = localStorage.getItem("activeSidebar") || "user";
    setActiveSideBar(storedActiveSidebar);
  }, [activeSideBar]);
  useEffect(() => {
    if (activeSideBar === "user") {
      setSideBar(userSidebarData);
    } else if (activeSideBar === "responsibilties") {
      setSideBar(responsibilitySideBar);
      const updatedSideBar = responsibilitySideBar?.map((item) => {
        const responsibilityKey = item?.responsibility;
        const userResponsibility = (user?.responsibility || {})?.[
          responsibilityKey
        ];

        return {
          ...item,
          givenResponsibilities: userResponsibility
            ? Object.entries(userResponsibility)
                .filter(([key, value]) => value) // Filter truthy values
                .map(([key, value]) => value) // Take only the values
            : [],
        };
      });

      setResponsibilitySidebar(updatedSideBar);
    } else {
      setSideBar(userSidebarData); // Default case
    }
  }, [activeSideBar, user?.responsibility]);

  const logoutbtn = async () => {
    const { latitude, longitude } = await getCurrentLocation();
    try {
      const response = await axios.post(baseUrlUser + "/employeeLogOut", {
        lat: latitude,
        long: longitude,
      });

      localStorage.clear();

      navigate("/");
    } catch (err) {
      throw new Error(err);
    }
    // })
    // .catch((err) => {
    // });
  };

  const handleCollapseToggle = useCallback((collapseKey) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [collapseKey]: !prev[collapseKey],
    }));
  }, []);
  const memoizedSidebarItems = useMemo(
    () =>
      sideBar.map((item) => {
        if (
          activeSideBar !== "user" &&
          item.responsibility &&
          (!user?.responsibility ||
            !Object.keys(user?.responsibility || {}).includes(
              item.responsibility
            ))
        ) {
          return null;
        }

        return (
          <React.Fragment key={item.id}>
            {!item.hasChildren ? (
              <NavLink
                to={item.path}
                className="sidebar-link"
                activeClassName="active"
              >
                <span className="icon-wrapper">{item.icon}</span>
                <span className="lable">{item.label}</span>
              </NavLink>
            ) : (
              <div
                className={`collapsible-item ${
                  openCollapse[item.label] ? "open" : ""
                }`}
                onClick={() => handleCollapseToggle(item.label)}
              >
                <span className="icon-wrapper">{item.icon}</span>
                <span className="lable">{item.label}</span>
                <span
                  className={`arrow ${
                    openCollapse[item.label] ? "up" : "down"
                  }`}
                >
                  &#9660;
                </span>
              </div>
            )}

            {item.label === "Notification" && openCollapse.notification && (
              <div className="submenu">
                <Link
                  to="/userNotification"
                  className={`sidebar-link ${
                    location.pathname === "/userNotification" ? "active" : ""
                  }`}
                >
                  <span className="label">Notification</span>
                </Link>
              </div>
            )}
          </React.Fragment>
        );
      }),
    [
      openCollapse,
      location.pathname,
      user?.responsibility,
      handleCollapseToggle,
      activeSideBar,
      sideBar,
    ]
  );

  return (
    <Sidebar
      memoizedSidebarItems={memoizedSidebarItems}
      logoutbtn={logoutbtn}
      // handlelogout={handlelogout}
      sideBar={sideBar}
      activeSideBar={activeSideBar}
      setActiveSideBar={setActiveSideBar}
    />
  );
}

const Sidebar = ({
  memoizedSidebarItems,
  logoutbtn,
  handlelogout,
  activeSideBar,
  setActiveSideBar,
  sideBar,
}) => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  let activebar = localStorage.getItem("activeSidebar");
const[socketRef,zegoRef]=useOutletContext()
  const handleToggle = (value) => {
    setActiveSideBar(value);
    localStorage.setItem("activeSidebar", value);
    if (value === "responsibilties") {
      const findItem = responsibilitySideBar?.filter((item) =>
        Object.keys(user?.responsibility || {}).includes(item.responsibility)
      );
      console.log(findItem, "findItem");
      if (findItem.length > 0) {
        navigate(findItem[0]?.path);
      }
    } else {
      navigate("/userDashboard");
    }
  };
  // useEffect(()=>{
  //   if(activebar==='responsibilties'&& user?.responsibility){

  //     if (sideBar && sideBar.length > 0) {
  //       // Navigate to the first item's path
  //       navigate(sideBar[0].path);
  //     }
  //   }else{
  //     navigate('/userDashboard')
  //   }

  // },[activebar])

  return (
    <div className="sidebar" id="sidebar">
      <div className="top">
        <Link to="/userdashboard">
          <img src={"../images/whitekicker.png"} alt="" className="logo" />
        </Link>
      </div>
      <div className="sidebar-toggle">
        <button
          className={`toggle-btn ${activeSideBar === "user" ? "active" : ""}`}
          onClick={() => handleToggle("user")}
        >
          User
        </button>
        <button
          className={`toggle-btn ${
            activeSideBar === "responsibilties" ? "active" : ""
          }`}
          onClick={() => handleToggle("responsibilties")}
        >
          Responsibility
        </button>
      </div>
      <div className="sidebar-container">{memoizedSidebarItems}</div>
      <p
      style={{
        marginLeft: "40px",
        marginTop: "10px",
        color: "#fff",
        display: "flex",
        gap: "10px",
        cursor: "pointer",
      }}
      // onClick={logoutbtn}
      >
        <LogoutAlert onClick={logoutbtn}zegoRef={zegoRef} />
        {/* <ExitToAppIcon className="icon" />
        <span className="spanfont">Logout</span> */}
      </p>
    </div>
  );
};

export default UserSidebar;
