import { toast } from "react-toastify";
import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";
import { Await } from "react-router-dom";

const employeeServices = {
  getAllEmployee: async (params) => {
   
    let finalUrl;
    if (Object.keys(params).length === 0) {
      finalUrl =`${ baseUrl}/getAllEmployee`
    } else if (params.designation === "" && params.name !== "") {
      finalUrl = `${baseUrl}/getAllEmployee?name=${params.name}`;
    } else if (params.designation !== "" && params.name !== "") {
      finalUrl = `${baseUrl}/getAllEmployee?designation=${params.designation}&name=${params.name}`;
    } else if (params.designation !== "" && params.name === "") {
      finalUrl = `${baseUrl}/getAllEmployee?designation=${params.designation}`;
    } else {
      finalUrl = `${baseUrl}/getAllEmployee`;
    }
    const response = await axios.get(finalUrl);
    return response.data.result;
  },
  getEmpofthemonthHistory:async()=>{
    const response = await axios.get(`${baseUrl}/eotmHistory`);
    return response.data.result;
  },
  getCRedentialRequests:async (data) => {
    const response = await axios.post(`${baseUrl}/cred-list`, data);
    return response.data.data;
  },
  fetchDeletedUsers:async () => {
    const response = await axios.get(`${baseUrl}/deleted-employees`, );
    return response.data.data;
  },
  addEmployee: async (data) => {
    const response = await axios.post(`${baseUrl}/addEmployee`, data);
    return response.result;
  },
  sendCredentials:async(id)=>{
    const response = await axios.post(`${baseUrl}/send-cred`, {id:id});
    return response.result;
  } ,
getEmployeeById:async(id)=>{
  const response=await axios.get(`${baseUrl}/getEmployeeById/${id}`)
  return response.data.result;
},
deleteEmployee:async(id)=>{
  const response=await axios.delete(`${baseUrl}/employee/${id}`)
  return response.data.result;
},
getemployeeProjects:async(id)=>{
  const response=await axios.post(`${baseUrl}/employee-projects`,{employeeId:id})
  return response.data.result;
},
revokeEmployeeProject: async (data, id) => {
  try {
    const response = await axios.put(`${baseUrl}/revoke-user`, {projectId:data?.projectId,employeeId:data.employeeId});
    return response.data.result;
  } catch (error) {
  }
},
  updateEmployee: async (data, id) => {
    try {
      const response = await axios.put(`${baseUrl}/editEmployee/${id}`, data);
      return response.data.result;
    } catch (error) {
    }
  },

  deleteEmployee: async (id) => {
    const response = await axios.delete(`${baseUrl}/employee/${id}`);
    return response.data.result;
  },

  addResponsibilities: async (data) => {
    const response = await axios.put(`${baseUrl}/addResponsibility`, data);
    return response.data.employee;
  },

  getResponsibilities: async (id) => {
    const response = await axios.get(`${baseUrl}/getResponsibility`);
    return response.data.result;
  },

  takeResponsibilityBack: async (data) => {
    try {
      await axios.put(`${baseUrl}/takeBackResponsibility`, data);
      // toast.success("Responsibility Taken Back");
    } catch (error) {
      // toast.error("Responsibility Failed To Take Back");
    }
  },

  getPayRolls: async () => {
    const response = await axios.get(`${baseUrl}/salaryPerMonthList`);

    return response.data.result;
  },

  changeProjectStatus: async (data) => {
    try {
      const response = await axios.put(`${baseUrl}/reviewedProject`, data);
      // toast.success("Project Status Changed");
      return response.data.result;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  },

  getEmpMonth: async () => {
    const response = await axios.get(`${baseUrl}/getEmpMonth`);
    return response.data.result.employeeId;
  },

  addEmpMonth: async (data) => {
    const response = await axios.post(`${baseUrl}/addEmpMonth`, data);
    return response.data.result;
  },

  suspendEmployeeTillDate: async (data) => {
    const response = await axios.post(`${baseUrl}/suspendEmployee`, data);
    return response.data.result;
  },

  employeeActiveStatus: async (data) => {
    const response = await axios.post(`${baseUrl}/suspendEmployee`, data);
    return response.data.result;
  },
  getTestersList: async (data) => {
    const response = await axios.get(`${baseUrl}/getTesterList`);
    return response.data.result;
  },
  getDepartmentList: async () => {
    const response = await axios.get(`${baseUrl}/getAllTeam
`);

    return response;
    
  },
  getDesignationList: async () => {
    const response = await axios.get(`${baseUrl}/department-config
`);

    return response;
    
  },
  // totalEmployeeByCategory: async (data) => {
  //   const response = await axios.get(
  //     `${baseUrl}/totalEmployeeByCategory`,
  //     data
  //   );
  //   return response.data;
  // },
};

export { employeeServices };
