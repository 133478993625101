import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Fade, List, Modal, Paper, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { formatDateTime } from "../../../utils/lib";
import { EmployeeList } from "../../components/EmployeeList";
import useReportHook from "../../hooks/ReportHook";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import Loading from "../protectedPages/Loading";
import { DataTable } from "./../../components/table/Data-Table";
import { AccessToList } from "./AccessToList";
import { AddCommentModal } from "./AddCommentModal";
import { TableModal } from "./TableModal";
import { PiMagnifyingGlassThin } from "react-icons/pi";
import { baseUrl } from "../../config/baseUrl";
import axios from '../../config/axiosAuth'
import Report from "./Report";
import Reportdetailedview from "./reportdetailedview";
import { useNavigate } from "react-router-dom";
export function AdminReport() {
  const {  isLoading, giveAccessToReportMutation,leaders } = useReportHook();
  // "allReports"
  const[data,setData]=useState([])
  const { data: employees } = useEmployeeHook();
  const [isEmployeeListOpen, setIsEmployeeListOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [giveAccessModalisOpen, setGiveAccessModalIsOpen] = useState(false);
  const [accessTypeModalIsOpen, setAccessTypeModalIsOpen] = useState(false);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [selectedEmployeeForAccess, setSelectedEmployeeForAccess] =
    useState("");
  const [designation, setDesignation] = useState("");
  const { departmentList } = useEmployeeHook();

  const [accessType, setAccessType] = useState([]);
  const access = [
    { id: 1, name: "view", checked: false },
    { id: 2, name: "edit", checked: false },
    { id: 3, name: "comment", checked: false },
  ];

  const openEmployeeList = () => {
    setIsEmployeeListOpen(true);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
useEffect(()=>{
  fetchReports()
},[designation,searchEmployee])

const fetchReports=async()=>{
  const plainUrl=baseUrl+'/getAllReport';
  const urlbyDesignation=`${baseUrl}/getAllReport?designation=${designation}`
  const urlbyemployee=`${baseUrl}/getAllReport?name=${searchEmployee}`
  const urlByBoth=`${baseUrl}/getAllReport?name=${searchEmployee}&designation=${designation}`
  
  let finalUrl;
  if(designation===''&&searchEmployee===''){
    finalUrl=plainUrl
  }else if(designation===''&&searchEmployee!==''){
    finalUrl=urlbyemployee
  }else if(designation!==''&&searchEmployee===''){
    finalUrl=urlbyDesignation
  }else if(designation!==''&&searchEmployee!==''){
    finalUrl=urlByBoth
  }else{
    finalUrl=plainUrl
  }
  try{
    const response=await axios.get(finalUrl)
    setData(response.data.result)

  }catch(err){
    console.log(err)
  }
}
  const handleEmployeeSelect = (employee) => {
    setSelectedEmployeeId(employee);
    setIsEmployeeListOpen(false);
    console.log(employee);
    handleGiveAccessModalOpen();
  };

  const filteredEmployees = employees?.filter((employee) => {
    return employee.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const handleGiveAccessModalOpen = () => {
    setGiveAccessModalIsOpen(true);
  };

  const handleAccessTypeModalClose = () => {
    setAccessTypeModalIsOpen(false);
  };

  const handleGiveAccess = async () => {
    const accessData = {
      accessType,
      employeeId: selectedEmployeeForAccess._id,
      accessTo: selectedEmployeeId._id,
    };

    giveAccessToReportMutation.mutate(accessData);
    setAccessTypeModalIsOpen(false);
  };

  const handleAccessToggle = (accessName) => {
    if (accessType?.includes(accessName)) {
      setAccessType(accessType?.filter((access) => access !== accessName));
    } else {
      setAccessType([...accessType, accessName]);
    }
  };

  const handleSelectEmployeeForAccess = (employee) => {
    setSelectedEmployeeForAccess(employee);
    setGiveAccessModalIsOpen(false);
    handleAccessTypeModalOpen();
  };

  const handleAccessTypeModalOpen = () => {
    setAccessTypeModalIsOpen(true);
  };
const navigate=useNavigate()
  const reportColoumns = useMemo(()=>[
    {
      header: "S.No.",
      cell: ({ row }) => row.index + 1,
    },

    {
      header: "Name",
      cell: ({ row }) => {
        return row.original.createdBy?.name;
      },
    },
    {
      header: "Designation",
      cell: ({ row }) => {
        return row.original.createdBy?.designation?.name;
      },
    },

    {
      accessorKey: "date",
      header: "Date",
      cell: ({ row }) => {
        const date = row.getValue("date");
        return <div className="w-[150px]">{formatDateTime(date)}</div>
      },
    },
    // {
    //   accessorKey: "taskAssigned",
    //   header: "Task Assigned",
    // },
    {
      cell: ({ row }) => {
        return row.original?.status;
      },
      header: "Reprt Task Completed",
    },
    {
      header: "Report",
      cell: ({ row }) => {
        return <span>{row.original?.reports[0]?.report?<Reportdetailedview report={row.original?.reports}/>:'No-Report'}</span>;
      },
    },
    {
      accessorKey: "status",
      header: "Status",
    },
    {
      header: "Add Comments",
      cell: ({ row }) => <AddCommentModal reportId={row.original._id} url={'/add-comment-in-report'} invalidateQueries=''refetch={fetchReports} reporter={true}/>

      // cell: ({ row }) => <AddCommentModal reportId={row.original._id} url={'/add-comment-in-report'}/>
      
    },
    {
      header: "Admin Comments",
      cell: ({ row }) => {
        return (
          <TableModal
            comments={row.original.commentByAdmin}
            reportId={row.original._id}
            isAdmin={true}
          />
        );
      },
    },
    {
      header: "User Comments",
      cell: ({ row }) => {
        return(
          <TableModal
            comments={row.original.commentByEmployee}
            reportId={row.original._id}
            isAdmin={false}
          />
        );
      },
    },
    // {
    //   header: "Add Comment",
    //   cell: ({ row }) => <AddCommentModal id={row.original._id} />,
    // },
  ],[data]);
  const leaderColumns = useMemo(()=>[
    {
      header: "S.No.",
      cell: ({ row }) => row.index + 1,
    },

    {
      header: "Name",
      cell: ({ row }) => {
        return row.original.name;
      },
    },
    {
      header: "EMployeeId",
      cell: ({ row }) => {
        return row.original?.employeeId;
      },
    },
    {
      header: "Designation",
      cell: ({ row }) => {
        return row.original?.designation?.name;
      },
    },
    {
      header: "Role",
      cell: ({ row }) => {
        return row.original?.role;
      },
    },

    {
      header: "Email",
      cell: ({ row }) => {
        return row.original?.email;
      },
    },
    // {
    //   accessorKey: "taskAssigned",
    //   header: "Task Assigned",
    // },
   
    {
      header: "View People ",
      cell: ({ row }) => <button className="button-17 !rounded-[10px]" onClick={()=>navigate(`/leaderTeam/${row.original?._id}`,{state:{name:row.original?.name}})}>View</button>

      
    },
  
   
   
    // {
    //   header: "Add Comment",
    //   cell: ({ row }) => <AddCommentModal id={row.original._id} />,
    // },
  ],[leaders]);
  console.log(leaders,'leaders')

  if (isLoading) {
    return <Loading />;
  }
  const handlechangetask = (e) => {
    setDesignation(e.target.value);
  };

  return (
    <>
      <div>
        <div className="flex justify-end mb-10">
          <div className="search_icon max-w-[200px] rounded-[5px] mr-2">
            <PiMagnifyingGlassThin />
            <input
              placeholder="Search"
              onChange={(e) => setSearchEmployee(e.target.value)}
            />
          </div>
          <select
            onChange={handlechangetask}
            className="h-full w-full border-0  p-2 text-[10px] max-w-[200px]  rounded-[5px]"
            name="cars"
            id="cars"
            value={designation}
          >
            <option value="" className="text-sm ">
              Select Department{" "}
            </option>
            {departmentList && departmentList?.length > 0
              ? departmentList?.map((department) => (
                  <option value={department._id} className="text-sm ">
                    {department?.teamName}
                  </option>
                ))
              : null}
          </select>
          <div style={{
              borderRadius: "5px",
              padding: "5px",
              backgroundColor: "#085394",
              color: "white",
              cursor: "pointer",
              marginBottom: "10px",
              marginLeft:"10px"
            }}   onClick={openEmployeeList}>

          <EditIcon
           
            style={{
              cursor: "pointer",
              color: "white",
              fontSize: "18px",
            }}

          
          />
            Add Responsibilties
          </div>
        </div>
        <DataTable columns={reportColoumns} data={data||[]} defaultTheme={true}  heading={'Recent Reports'} TableHeight={'350px'}/>


<Report/>
        <EmployeeList
          isEmployeeListOpen={isEmployeeListOpen}
          setIsEmployeeListOpen={setIsEmployeeListOpen}
          searchText={searchText}
          handleSearch={handleSearch}
          handleEmployeeSelect={handleEmployeeSelect}
          filteredEmployees={filteredEmployees}
        ></EmployeeList>

<DataTable columns={leaderColumns} data={leaders||[]} defaultTheme={true}  heading={'Leaders with Allocations'} TableHeight={'350px'}/>


        <Modal
          open={accessTypeModalIsOpen}
          onClose={handleAccessTypeModalClose}
          closeAfterTransition
        >
          <Fade
            in={accessTypeModalIsOpen}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              padding: "16px",
            }}
          >
            <Paper>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <TextField
                  fullWidth
                  label={`What access do you want to give ${selectedEmployeeForAccess?.name}?`}
                  variant="outlined"
                  value={searchText}
                  onChange={handleSearch}
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                />
              </div>
              <List
                style={{
                  maxHeight: "400px",
                  overflow: "auto",
                }}
              >
                {access.map((access) => (
                  <div
                    style={{
                      width: "15%",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                    key={access.id}
                  >
                    <input
                      type="checkbox"
                      checked={accessType.includes(access.name)}
                      onChange={() => handleAccessToggle(access.name)}
                    />
                    <label>{access.name}</label>
                  </div>
                ))}
                <Button
                  variant="contained"
                  onClick={handleGiveAccess}
                  color="primary"
                >
                  Add
                </Button>
              </List>
            </Paper>
          </Fade>
        </Modal>

        <AccessToList
          searchText={searchText}
          selectedEmployeeId={selectedEmployeeId}
          giveAccessModalisOpen={giveAccessModalisOpen}
          setGiveAccessModalIsOpen={setGiveAccessModalIsOpen}
          handleSearch={handleSearch}
          filteredEmployees={filteredEmployees}
          handleAccessToggle={handleAccessToggle}
          handleSelectEmployeeForAccess={handleSelectEmployeeForAccess}
        ></AccessToList>
      </div>
    </>
  )
}
