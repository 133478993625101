import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
} from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";

// import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { useForm, Controller } from "react-hook-form";
import { Edit } from "lucide-react";
import useReportHook from "../../hooks/ReportHook";
import { Dialog, DialogContent, DialogFooter, DialogTitle, DialogTrigger } from "../../../PageResponsibility/src/admin/components/ui/dialog";
import { cn } from "../../../PageResponsibility/src/utils/lib";

export const EditTeamReportModal = ({  id,report }) => {
  const { editTeamReportUserMutation } = useReportHook();
const[open,setOpen]=useState()

  const { control, handleSubmit, setValue, formState,reset } = useForm({
    defaultValues: {
      report: report || "",
    },
  });
  const { errors } = formState;

 

  const onSubmit = (data) => {
    console.log("Form data:", data);

    editTeamReportUserMutation.mutate({
      _id:  id,
      report:data?.report,
    });
    console.log("Form data: edited", data);
reset()
    setOpen(false)
  };
  const statusOptions = ['Pending', 'InProgress', 'Completed', 'Planning', 'Approved', 'Delivered'];
  return (
    <Dialog open={open} onClose={setOpen}>
       <DialogTrigger asChild className="w-[75px]">
          <p className={cn("lg:text-white text-white text-left cursor-pointer flex p-2 w-full text-center w-full",)} onClick={()=>setOpen(true)}> <Edit className="icon mr-[10px] !m-auto text-[blue]" /> </p>
      </DialogTrigger>
      <DialogContent>
      <DialogTitle>Edit Report</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="report"
            control={control}
            rules={{
              required: "This Field cannot be empty ",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Report"
                margin="normal"
              />
            )}
          />
          {errors.report && (
            <p className="error-message">{errors.report.message}</p>
          )}

      

          <DialogFooter>
            <Button type="button" onClick={()=>setOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
