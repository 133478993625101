import React, { useMemo } from 'react'
import { DataTable } from '../../components/table/Data-Table'
import useEmployeeHook from '../../hooks/useEmployeeHook'

const DeletedUsers = () => {
    const {deletedUsers}=useEmployeeHook()
    const columns = useMemo(
        () => [
          {
            header: "S.No",
            cell: ({ row }) => {
              return <span>{row.index + 1}</span>;
            },
          },
    
          {
            cell: ({ row }) => {
              return <span>{row.original?.name}</span>;
            },
            header: "Name",
          },
          {
            cell: ({ row }) => {
              return <span>{row.original?.email}</span>;
            },
            header: "Email",
          },
    
          {
            cell: ({ row }) => {
              return <span>{row.original?.employeeId}</span>;
            },
            header: "Employee Id",
          },
          {
            accessorKey: "designation",
            cell: ({ row }) => row.original?.designation?.name,
          },
          {
            cell: ({ row }) => row.original?.role,
            header: "Role",
          },
    
        //   {
        //     // accessorKey: "password",
        //     cell: ({ row }) =>
        //       moment(row?.original?.createdAt).format("DD-MM-yyyy"),
        //     header: "Request sent on",
        //   },
    
          // {
          //   // accessorKey: "password",
          //   cell: ({ row }) => <Password password={row?.original?.password} />,
          //   header: "Password",
          // },
        //   {
        //     // accessorKey: "password",
        //     cell: ({ row }) => <ResendCredentials employeeId={row.original?.employeeId?._id} />,
        //     header: "Send Credentials",
        //   },
        ],
        [deletedUsers]
      );
  return (
  <DataTable data={deletedUsers||[]} heading='Deletd Users' columns={columns} defaultTheme={true}/>
  )
}

export default DeletedUsers
