import React, { useState } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

// import {
//   AlertDialog,
//   AlertDialogAction,
//   AlertDialogCancel,
//   AlertDialogContent,
//   AlertDialogDescription,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogTitle,
//   AlertDialogTrigger,
// } from "../SharedUiComponents/Dialog/alert-dialog";
// import { cn } from "../../Utils/HelperFunctions";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "./alert-dialog";
import { cn } from "../../../utils/lib";
import ScreenShareRoomDetailsStore from "../../../../../user/store/ScreenShareRoomDetailsStore";

const LogoutAlert = ({className, refprop,onClick,}) => {
  const[open ,setOpen]=useState(false)
// const{ref}=useComponentVisible()
const {
  setResharingScreen,
  resharingScreen,
  screenShareRoom,
  userIdFromStore,

  screenShareSteamIdFromStore,
} = ScreenShareRoomDetailsStore();
const [socketRef, zegoRef,reshareScreen,stream] = useOutletContext();

    const navigate=useNavigate()

    const logout=async()=>{
      if (stream) {
        console.log(stream,'zegoRef')
        // zegoRef.current.destroyStream(stream.current)
        // stream.current.stopCaptureVideo()

        console.log('Screen sharing stopped');
      }
   await   socketRef.current.emit("withdrawScreenShareStreamFromRoom", {
        streamId: screenShareSteamIdFromStore,
        room: screenShareRoom,
        userId: userIdFromStore,
      });
   await   socketRef.current.emit("exitFromScreenshareRoom", {
        userId: userIdFromStore,
        room: screenShareRoom,
      });

    await  zegoRef.current.stopPublishingStream(screenShareSteamIdFromStore);
   await   zegoRef.current.logoutRoom(screenShareRoom);
  //  zegoRef.current.destroyEngine(zegoRef.current);

    // Reset the zegoRef
    // zegoRef.current = null;
      onClick()
      }
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild className="w-[75px]">
          <p className={cn("lg:text-white text-white text-left cursor-pointer flex p-2",className)} onClick={()=>setOpen(true)}> <ExitToAppIcon className="icon mr-[10px]" /> Logout</p>
      </AlertDialogTrigger>

      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle className='font-bold text-2xl'>Logout </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription >
          Are you sure you want to Logout ?
        </AlertDialogDescription>
        <AlertDialogFooter ref={refprop}>
          <AlertDialogCancel  ref={refprop}className={cn(buttonstyles,'bg-errorPrimary')}>Cancel</AlertDialogCancel>
          <AlertDialogAction ref={refprop} onClick={logout} className={cn(buttonstyles,'bg-successPrimary')}>Logout</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LogoutAlert;
const buttonstyles = "w-[110px] !h-[35px] !hover:text-white rounded-[8px] !min-h-[35px] border-none text-white text-[14px] font-medium font-md uppercase !bg-[#2c3e50]";
