import moment from "moment";
import { DataTable } from "./Data-Table";
import useEventStore from "../../store/eventStore";
import { useLocation } from "react-router-dom";
import { DescriptionModal } from "../modals/DiscriptionModal";
import CommonAlert from "../ui/CommonAlert";
import { Trash } from "lucide-react";
import { EditHolidayModal } from "../modals/EditHolidayEvent";
import { baseUrl } from "../../config/baseUrl";
import axios from "axios";

const HolidayTable = ({handleallevents}) => {
  const { holidays } = useEventStore();
  const state=useLocation()

  const deleteHoliday =async(id) => {
    await axios.post(`${baseUrl}/deleteHoliday`,{_id:id});
    handleallevents()
  };
  const Columns = [
    { header: "S.No", cell: ({ row }) => row.index + 1 },
    {
      header: "Occasion",
      cell:({row})=>row.original.title
    },
    {header:'Description',
      cell:({row})=> <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row.original.description.substring(0,14)}....`}</span>} data={row.original.description}/>
  
    },
    {
      header: "Date",
      cell: ({ row }) => moment.utc(row.original.date).format("DD-MM-YYYY HH:mm"),
    },
    {
      header: "Edit",
      cell: ({ row }) =><EditHolidayModal id={row?.original?._id} activeCategory='Holiday'data={row?.original}recall={handleallevents}/>,
    },
    {
      header: "Delete",
      cell: ({ row }) => (
        <CommonAlert
          trigger={<Trash style={{ color: "red", margin: "auto" }} />}
          onClick={() => deleteHoliday(row.original?._id)}
          heading={"Delete"}
          text={"Are you sure you want to delete ?"}
        />
      ),
    },
  ];

  return (
    <DataTable
      columns={Columns}
      data={(!state?.state?holidays:state?.state?.data)||[]}
      heading="Holidays list"
      defaultTheme={true}
      TableHeight={"250px"}
    />
  );
};

export default HolidayTable;
