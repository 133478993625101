import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import moment from "moment";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { baseUrlUser } from "../../../../../user/config/baseUrl";
import { useParams } from "react-router-dom";

export const DailyLogs = ({ id, url, user, date ,monthly}) => {
  const [data, setData] = useState([]);
const ID=useParams()

  const handleDailyAttendance = () => {
    let finalId=monthly?ID.id:id
    const currentDate = new Date();
    const finalurl = user ? baseUrlUser + url : baseUrl + "/dailyAttendance";
    axios
      .post(finalurl, {
        id: finalId,
        date: date ? date : currentDate,
      })
      .then((res) => {
        setData([res.data.result]);
      })
      .catch((err) => {});
  };
  const time = (time) => {
    const totalMinutes = time;
    const hours = Math.floor(totalMinutes / 60); // Get whole hours
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes}`;
  };

  return (
    <Dialog>
      <DialogTrigger>
        <button onClick={handleDailyAttendance} className="button-17">
          View
        </button>
      </DialogTrigger>
      <DialogContent className='!max-w-fit w-[80%]'>
        <div className="w-full h-full overflow-auto max-h-[450px] mt-[10px]">

        {data && (
          <div>
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                paddingTop:0
              }}
            >
              <table>
                <thead className="sticky top-[-1px]">
                  <tr
                    style={{
                      backgroundColor: "#f1f4f7",
                      color: "gray",
                    }}
                  >
                    <th>CheckIn</th>

                    <th>CheckOut</th>
                    <th>CheckIn Location</th>
                    <th>CheckOut Location</th>

                   

                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {data?.map((log, index) => {
                    const maxPairs = Math.max(
                      log.loggedIn?.length || 0,
                      log.loggedOut?.length || 0
                    );

                    const pairs = Array.from({ length: maxPairs }, (_, i) => ({
                      loggedIn: log.loggedIn?.[i] || null,
                      loggedOut: log.loggedOut?.[i] || null,
                      duration: log.duration?.[i] || null,
                      loginLocation:log.loginLocation?.[i]||null,
                      logoutLocation:log.logoutLocation?.[i]||null

                    }));

                    return pairs.map((pair, i) => (
                      <tr key={`${index}-${i}`}>
                        <td>
                          {pair.loggedIn
                            ? moment(pair.loggedIn).format("DD-MM-YYYY, LT")
                            : "No CheckIn"}
                        </td>

                        <td>
                          {pair.loggedOut
                            ? moment(pair.loggedOut).format("DD-MM-YYYY, LT")
                            : "No CheckOut"}
                        </td>
                        <td>
                          {pair?.loginLocation?pair?.loginLocation:'NO Data found' }
                        </td>
                        <td>
                          {pair?.logoutLocation? pair?.logoutLocation:"NO Data found"}
                        </td>
                        <td>
                          {pair?.duration }
                        </td>
                       
                      </tr>
                    ));
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
