import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";

const allocationService = {
  getAllAllocations: async () => {
    
    try {
      const response = await axios.get(`${baseUrl}/allocations`);
      return response.data.result;
    } catch (error) {
      throw new Error(error);
    }
  },
 
  
  
 

  

  getOneAllocation: async (id) => {
    const res = await axios.get(`${baseUrl}/allocations/${id}`);
    return res.data;
  },

  

 

  editAllocation: async ( data) => {
    const res = await axios.put(`${baseUrl}/allocations`,{ id:data.id,...data.data});
    return res.data.result;
  },

  deleteAllocation: async (id) => {
    const res = await axios.delete(`${baseUrl}/allocations/${id}`);
    return res.data.result;
  },

  addAllocation: async (data) => {
    const res = await axios.post(`${baseUrl}/allocations`, data);
    return res.data.result;
  },
 
};

export { allocationService };
