/* eslint-disable no-unused-vars */
import EditNoteIcon from "@mui/icons-material/EditNote";
import React, { useEffect, useMemo, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../config/axiosAuth";
import { columns } from "./columns";
import { DataTable } from "../../components/table/Data-Table";
import Loading from "../protectedPages/Loading";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { useLocation, useParams } from "react-router-dom";
import { base, baseUrl } from "../../config/baseUrl";
import DatePicker from "react-datepicker";
import { months } from "../../../utils/lib";
import { AddCommentModal } from "./AddCommentModal";
import { CommentDialog } from "../../components/modals/CommentDialog";
import Reportdetailedview from "./reportdetailedview";
import moment from "moment";
import { DescriptionModal } from "../../components/modals/DiscriptionModal";

const LeaderTeamReport = () => {
const [reports,setReports]=useState([]);
const params=useParams()
const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [startDate, setStartDate] = useState(new Date(currentYear, selectedMonth - 1, 1));
  const [endDate, setEndDate] = useState(currentDate);

  useEffect(() => {
    // Update startDate to first day of selected month
    const newStartDate = new Date(currentYear, selectedMonth - 1, 1);
    setStartDate(newStartDate);

    // Update endDate
    if (selectedMonth === currentMonth) {
      // If current month, set to current date
      setEndDate(currentDate);
    } else {
      // If not current month, set to last day of selected month
      const lastDay = new Date(currentYear, selectedMonth, 0);
      setEndDate(lastDay);
    }
  }, [selectedMonth]);

console.log(params,'params',params.id)
const getMonthlyReports=async()=>{
    const year = startDate.getFullYear();
        try{
        const response=await axios.get(baseUrl+`/employee-team-report/${params.id}`,{
            employeeId:params.id,
            month:selectedMonth,
            year:year,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD')
        })
        setReports(response.data.result)
        console.log(response,'monthlyreports')

    }catch(err){

    }
}
useEffect(()=>{
    getMonthlyReports()
},[startDate,selectedMonth,endDate,])
//   const { data: employees, isLoading: employeeLoading } = useEmployeeHook();
const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };
  
const reportColumns=useMemo(()=> [
    {
      header: "S.No",
      cell: ({ row }) => {
        return <span>{row.index + 1}</span>;
      },
    },
    {
        header: "Date",
        cell: ({ row }) => {
          return <span>{moment(row.original?.createdAt).format('DD-MM-YYYY')}</span>;
        },
      },
    
      {
        header: "Report",
        cell: ({ row }) => {
            return row.original.report?.length&& <DescriptionModal trigger={row.original.report.substring(0,10) } data={row.original?.report}/>
        },
      },
    // {
    //   header: "Add Comment",
    //   cell: ({ row }) => <AddCommentModal reportId={row.original?.report?._id}  url={'/add-comment-in-report'} report={true}/>,
    // },
    // {
    //   cell: ({ row }) => <CommentDialog reportId={row.original?.report?._id} url={"/employee-comment-in-reports"} report={true}  />
    //   ,
    //   header: "Comments",
    // },
  ],[reports])
  return <>
    <div style={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom:'1rem'}}>
        <select
          style={{ width: "10vw" }}
          name="month"
          id="Select"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          {months?.map((item)=><option value={item.value}>{item.label}</option>)}
        </select>

        {/* <div style={{ width: "10vw" }}>
          <DatePicker
             dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
          />
        </div>
        <div style={{ width: "10vw" }}>
          <DatePicker
             dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
          />
        </div> */}
      </div>
    <DataTable columns={reportColumns} data={reports||[]}defaultTheme={true} TableHeight={'350px'}/>
    </>
};

export default LeaderTeamReport;
