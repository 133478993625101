import React, { useEffect, useState } from "react";

import "./sendNotification.css";
import { baseUrlUser } from "../../config/baseUrl";
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'
import { QueryClient, useQueryClient } from "react-query";

const UserNotification = () => {
  const [notification, setNotification] = useState([]);
  const queryclient=useQueryClient()
  const getAllNotifications = async () => {
    try {
      const response = await axios.get(baseUrlUser + "/getAllNotification");
      setNotification(response.data.result);
      console.log(response.data.result);

     
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, []);
const markAsRead=async()=>{
  try{

    await axios.put(baseUrlUser + "/markRead", {});
    queryclient.invalidateQueries('user')
    getAllNotifications()

  }catch{

  }
}
const markSingleRead=async(id)=>{
  try{

    await axios.put(baseUrlUser + "/markSingleRead", {notificationId:id});
    getAllNotifications()
    queryclient.invalidateQueries('user')

  }catch{

  }
}
  return (
    <>
      <h2>All Notifications</h2>
      
      <div className="notification_box">
        <div onClick={markAsRead}>Mark All read</div>
        {notification?.map((notification) => (
          <div
            className="notification cursor-pointer "
            style={
              notification?.isRead === false
                ? { backgroundColor: "#b9d2fb" }
                : {}
            }
            onClick={()=>markSingleRead(notification?._id)}
          >
            <div
              style={{
                padding: "10px",
              }}
            >
              <div className="notification__header">
                <h6>{notification?.title}</h6>
              </div>
              <div className="notification__body">
                <p>{notification?.body}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserNotification;
