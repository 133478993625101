import { createContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import io from "socket.io-client";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { base } from "../config/baseUrl";
import ScreenShareRoomDetailsStore from "../store/ScreenShareRoomDetailsStore";
import { logoutFunc } from "../../utils/lib";
import useUserHook from "../hooks/userHook";

// export const socketContext = createContext();

const SocketProvider = ({ children }) => {
  const {
    setResharingScreen,
    resharingScreen,
    screenShareRoom,
    userIdFromStore,

    screenShareSteamIdFromStore,
  } = ScreenShareRoomDetailsStore();
  const {
    setRoom,
    isInGroupMeetingTab,
    setScreenShareSteamIdFromStore,
    screenShareCallStarted,
    setScreenShareCallStarted,
    setUserId,
    userId,
    setUserIdFRomStore,
  } = ScreenShareRoomDetailsStore();
  const { user, userIsError } = useUserHook();
  const roomId = useRef(null);
  const localscreenShareStream = useRef(null);
  const myUserId = useRef(null);
  const screenStreamId = useRef(null);
  const TOKEN = localStorage.getItem("token");
  const socketRef = useRef(null);
  const zegoRef = useRef(null);
  const stream=useRef(null)

  const [metaPressed, setMetaPressed] = useState(false);
  const [windowsLocked, setWindowsLocked] = useState(false);
  console.log(metaPressed,'mettttttbahr')

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.metaKey || event.key === "Meta") {
        setMetaPressed(true);
      }

      if (metaPressed && event.key === "l") {
        setWindowsLocked("true");
        console.log(metaPressed,'mettttttandhar')
        // Note: The actual system lock won't occur in the browser
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Meta") {
        setMetaPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [metaPressed]);

  useEffect(() => {
    socketRef.current = io.connect(base, {
      extraHeaders: {
        token: TOKEN,
        sessionId: localStorage.getItem("videoSessionId"),
      },
    });

    let appId = 2078651070;
    let serverSecret = "c93a8ab5be0f6e7bf626f1bfb87e77b2";

    zegoRef.current = new ZegoExpressEngine(appId, serverSecret);
    // zegoRef.current.enableMultiRoom(true);
    zegoRef.current.setDebugVerbose(false);
    zegoRef.current.setSoundLevelDelegate(true, 1000);
    zegoRef.current.on("adminKickedYouOutFromScreenShareRoom", (data) => {
      setResharingScreen(true);
      socketRef.current.emit("withdrawScreenShareStreamFromRoom", {
        streamId: screenShareSteamIdFromStore,
        room: screenShareRoom,
        userId: userIdFromStore,
      });
      socketRef.current.emit("exitFromScreenshareRoom", {
        userId: userIdFromStore,
        room: screenShareRoom,
      });

      zegoRef.current.stopPublishingStream(screenShareSteamIdFromStore);
      zegoRef.current.logoutRoom(screenShareRoom);
    });
    socketRef.current.on("adminRequestingToReshareYourScreen", (data) => {
      reshareScreen();
      if (data.id === user?._id) {
      }
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (!screenShareCallStarted) {
        socketRef.current.emit("startScreenShare");

        socketRef.current.on("screenSharingStarted", async (data) => {
          roomId.current = data.roomId;
          setRoom(data.roomId);
          myUserId.current = data.userId;
          setUserIdFRomStore(data.userId);
          setUserId(data.userId);
          await zegoRef.current.logoutRoom(screenShareRoom);

          startCallAndScreenShare();
        });
      }
    }, 1000);
  }, []);

  const startCallAndScreenShare = async () => {
    setScreenShareCallStarted(true);
    setTimeout(() => {
      setResharingScreen(false);
    }, 2000);
    // setDoNotShowButton(false);
    const token = localStorage.getItem("zegoToken");

    try {
      const loginResponse = await zegoRef.current.loginRoom(
        roomId.current,
        token,
        { userID: myUserId.current || userId, userName: user?.name },
        { userUpdate: true },
        { isUserStatusNotify: true }
      );
      zegoRef.current.setDebugVerbose(false);
      if (loginResponse == true) {
        try {
          const screenStream = await zegoRef.current.createZegoStream({
            screen: {
              videoBitrate: 1500,
              audio: false,
              video: {
                quality: 2,
                frameRate: 15,
                width: "100%",
                height: "100%",
              },
            },
          });
          stream.current=screenStream
          screenStreamId.current = new Date().getTime().toString();
          setScreenShareSteamIdFromStore(screenStreamId.current);
          zegoRef.current.startPublishingStream(
            screenStreamId.current,
            screenStream,
            { roomID: roomId.current }
          );
          const settings = screenStream.stream
            .getVideoTracks()[0]
            .getSettings();
          if (
            settings.displaySurface === "browser" ||
            settings.displaySurface === "window"
          ) {
            zegoRef.current.stopPublishingStream(screenStreamId.current);
            alert(
              "you have not shared the full window so we are logging you out"
            );

            // if (!windowsLocked) {
              logoutFunc();
            // }

            // localStorage.clear();
            // navigate("/");
          }
          screenStream.playVideo(
            document.querySelector("#local_share_screen"),
            {
              enableAutoPlayDialog: true,
            }
          );
          socketRef.current.emit("addStreamToListForShareScreen", {
            streamId: screenStreamId.current,
            room: roomId.current,
            userId: myUserId.current,
          });
        } catch (error) {
          console.error(error);
          if ((error.code = 1103042)) {
            // if (!windowsLocked) {
              // logoutFunc();
            // }

            // localStorage.clear();
            // navigate("/");
            zegoRef.current.stopPublishingStream(screenStreamId.current);
          }

          if (
            error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError"
          ) {
            alert(
              "Please grant permission to access your camera and microphone."
            );
          } else {
            alert(error);
            //   endVideoCall();
          }
        }
      } else if (loginResponse !== true) {
        console.error("Failed to login to the room");
        alert(
          "Failed to login to the room. Please check your credentials and try again."
        );
      }

      zegoRef.current.on("publisherStateUpdate", (result) => {
        var state = result["state"];
        var streamID = result["streamID"];
        var errorCode = result["errorCode"];
        var extendedData = result["extendedData"];
        if (state == "PUBLISHING") {
        } else if (state == "NO_PUBLISH") {
        } else if (state == "PUBLISH_REQUESTING") {
        }

        if (errorCode !== 0) {
        }
      });
      zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
        if (updateType === "DELETE") {
          // endVideoCall();
        } else if (updateType === "ADD") {
        }
      });

      const config = {
        logLevel: "disable",
        remoteLogLevel: "disable",
        logURL: "disable",
      };
      zegoRef.current.setLogConfig(config);
    } catch (err) {
      // endVideoCall();
      // if (!windowsLocked) {
        // logoutFunc();
      // }
      // localStorage.clear();
      // navigate("/");
      zegoRef.current.stopPublishingStream(screenStreamId.current);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      zegoRef.current.on("screenSharingEnded", (stream) => {
        setScreenShareCallStarted(false);

        socketRef.current.emit("withdrawScreenShareStreamFromRoom", {
          streamId: screenStreamId.current,
          room: roomId.current,
          userId: myUserId.current,
        });
        socketRef.current.emit("exitFromScreenshareRoom", {
          userId: myUserId.current,
          room: roomId.current,
        });

        zegoRef.current.stopPublishingStream(screenStreamId.current);
        zegoRef.current.logoutRoom(screenShareRoom);
        if (!resharingScreen) {
          // if (!windowsLocked) {
            logoutFunc();
          // }

          // localStorage.clear();
          // navigate("/");
        }
      });
    }, 1000);
  }, []);
  useEffect(() => {
    setScreenShareSteamIdFromStore(screenStreamId.current);
  }, [screenStreamId.current]);

  const openWindow = () => {
    const url = "/screen";
    const windowProperties = "width=500,height=500";

    window.open(url, "_blank", windowProperties);
  };
  const reshareScreen = async () => {
    await zegoRef.current.logoutRoom(screenShareRoom);
    await setResharingScreen(true);
    socketRef.current.emit("withdrawScreenShareStreamFromRoom", {
      streamId: screenShareSteamIdFromStore,
      room: screenShareRoom,
      userId: myUserId.current || userId,
    });
    socketRef.current.emit("exitFromScreenshareRoom", {
      userId: myUserId.current,
      room: screenShareRoom,
    });

    setScreenShareCallStarted(false);
    await zegoRef.current.stopPublishingStream(screenStreamId.current);
    await zegoRef.current.logoutRoom(screenShareRoom);
    socketRef.current.emit("startScreenShare");
    socketRef.current.on("screenSharingStarted", (data) => {
      roomId.current = data.roomId;
      setRoom(data.roomId);
      myUserId.current = data.userId;
      setUserId(data.userId);

      // startCallAndScreenShare();
    });
  };
  console.log(zegoRef,'zegoRef',screenShareSteamIdFromStore)
  return (
    <Outlet context={[socketRef, zegoRef, reshareScreen,stream]}>{children}</Outlet>
  );

  //   return (
  //     // <socketContext.Provider value={{ socketRef, zegoRef }}>
  //     //   {children}
  //     // </socketContext.Provider>
  //     <>
  //       <Outlet context={[socketRef, zegoRef]} />
  //     </>
  //   );
};

export default SocketProvider;
