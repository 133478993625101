import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { Button } from "../../components/ui/button";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { SelectCustomStyles } from "../../../utils/styles";
import useAllocationHook from "../../hooks/useAllocationHook";
import { NestedFields } from "./NestedAddAllocations";

const AddAllocation = () => {
  const params = useParams();
  const id = params.projectId;
  const[error,setError]=useState('')
  const { data: employees } = useEmployeeHook();
  const{addAllocationMutation}=useAllocationHook()
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const submitRef = useRef(false);
  const { register, handleSubmit, setValue, watch, formState, control, reset } =
    useForm({
      defaultValues: {},
    });
  const {
    fields,
    append,
    prepend,
    remove: removeTeam,
    swap,
    move,
    insert,
    replace,
  } = useFieldArray({
    control,
    name: "allocations",
  });

  const handleClick = () => {
    submitRef.current = true;
  };
  const onSubmit = (data) => {
    if(data?.allocations&&data?.allocations?.length>0){
      setError("")
      console.log(data,'dataaa')
      if (submitRef.current === true) {
        // setIsComponentVisible(false);
        const response = addAllocationMutation.mutate( data.allocations );
      }
      reset()

    }else{
      setError("Create an allocation to proceed !")
    }
  };
  const { errors } = formState;
  const allocations = watch("allocations");

  // Use useEffect to track changes in allocations length
  useEffect(() => {
    if (allocations && allocations.length > 0) {
      setError("");
    }
  }, [allocations]);
  

  useEffect(() => {
    if (employees) {
      setEmployeeOptions(
        employees?.map((item) => ({ value: item._id, label: item.name }))
      );
    }
  }, [employees]);

 

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error&&<p className="error-message">{error}</p>}
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id}>
              <div className="form-row">
                <div className="form-group">
                  <Controller
                   rules={{
                    required: "Please select a Employee To add Allocation",
                  }}
                    render={({ field }) => {
                      const selectedId = field?.value || "";
                      const selectedOption = employeeOptions?.find(
                        (emp) => emp.value === selectedId
                      );
                     
                      return (
                        <ReactSelect
                          styles={SelectCustomStyles}
                          isClearable
                          value={
                            selectedOption
                              ? {
                                  value: selectedOption.value,
                                  label: selectedOption.label,
                                }
                              : null
                          }
                          onChange={(option) => {
                            const value = option ? option.value : null;
                            field.onChange(value); // Update field with the new value
                          }}
                          //   onChange={(selectedOptions) => {
                          //     const selectedValues = selectedOptions
                          //       ? selectedOptions.map((option) => option.value)
                          //       : [];
                          //     field.onChange(selectedValues);
                          //   }}
                          //   value={employeeOptions?.filter(
                          //     (option) =>
                          //       field.value && field.value.includes(option.value)
                          //   )}
                          options={employeeOptions}
                          placeholder={"Select EMployee ...."}
                        />
                      );
                    }}
                    name={`allocations.${index}.leaderId`}
                    control={control}
                  />
                  { errors?.allocations?.[index]?.leaderId?.message&&<p  className="error-message"> {errors?.allocations?.[index]?.leaderId?.message}</p>}

                  {/* <p>{`${errors}?.${message}.allocations.${index}.leaderId`}</p> */}
                </div>
                
               
              </div>
                <NestedFields
                errors={errors}
                nestIndex={index}
                removeTeam={removeTeam}
                {...{ control, register }}
                // employeeOptions={teamMembers?.[index]}
                // modules={modules}
                watch={watch}
                setValue={setValue}
                // project={project}
                // projectStartDate={state?.state?.startDate}
                // projectEndDate={state?.state?.endDate}
              />
            </li>
          );
        })}
      </ul>
      <hr />
      <button
        type="button"
        onClick={() => append({})}
        className="flex items-center justify-center mt-[10px] gap-2 mb-[30px]"
      >
        <div
          className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]"
          style={{ backgroundColor: "rgb(8,83,148,0.3)" }}
        >
          +
        </div>
        Employee
      </button>

      <Button type="submit" ref={submitRef} onClick={handleClick}>
        Save
      </Button>
    </form>
  );
};

export default AddAllocation;
const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
    minWidth: "200px",
  }),
};
