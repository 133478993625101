import EditNoteIcon from "@mui/icons-material/EditNote";
import { CircularProgress, Pagination, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import useTaskMangementHook from "./TaskManagement.hooks";
import useUserStore from "./../../store/userStore";
import useReportHook from "../../hooks/ReportHook";
import DoneIcon from "@mui/icons-material/Done";
import Loading from "../protectedPages/Loading";
import Error from "../protectedPages/Error";
import { EditTaskModal } from "./Modal/EditTaskModal";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";

import { useNavigate } from "react-router-dom";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { AddCommentModal } from "../../components/modals/AddCommentModal";

export function TaskManagement() {
  const user = useUserStore((state) => state.user);

  const { tasks, isLoading } = useTaskMangementHook(user?._id);
const navigate=useNavigate()
  const [inputComment, setInputComment] = useState("");
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);

  const { addReportUserMutation } = useReportHook();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(7);

  const totalPages = Math.ceil(tasks?.length / pageSize);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  const handleEditClick = (task) => {
    setCurrentTask(task);
    setEditModalOpen(true);
  };

  const handleSubmit = async (task) => {
    const reportData = {
      project: task.projectId._id,
      taskAssigned: task?.task,
      taskCompleted: inputComment,
      createdBy: user._id,
    };

    addReportUserMutation.mutate(reportData);
    setInputComment("");
  };

 
  const usercolumns = useMemo(
    () => [
      { header: "S.No", cell: ({ row }) => row.index + 1 },
      {
        header: "Project Name",
        cell: ({ row }) => (
          <div className="!w-[150px]">
        {  row?.original?.projectId?.projectName}
          </div>
        ),
      },
      {
        header: "Project Start Date",
        cell: ({ row }) =>row?.original?.projectId?.projectStartDate?.substring(0, 10)
      },
      {
        header: "Project End Date",
        cell: ({ row }) =>
          row.original?.projectId?.projectEndDate?.substring(0, 10)
      },
      {
        header: "Project Status",
        cell: ({ row }) =>
          row?.original?.projectId?.status
      },
      {
        header: "Task Assigned",
        cell: ({ row }) =>
          row.original?.task
            
      },
     
      {
        header: "Task Start Date",
        cell: ({ row }) =>
          row.original?.start_date?.substring(0, 10) 
      },
      {
        header: "Task End Date",
        cell: ({ row }) =>
          row?.original?.end_date?.substring(0, 10) 
      },
      {
        header: "Status",
        cell: ({ row }) =>
          <div style={{color:row?.original?.status==='Completed'?'green':row?.original?.status==='Pending'?'red':'blue'}}>{row?.original?.status}</div>

      },
      {
        header: "Edit",
        cell: ({ row }) =><EditTaskModal task={row?.original}/>
         
      },
      {
        header: "Add Comment",
        cell: ({ row }) => (
          <AddCommentModal id={row.original._id} model={"task"} user={true}/>
        ),
      },
      {
        header: "Comment",

        cell: ({ row }) => {
          return <CommentDialog id={row.original._id} model="task" user={true}/>;
        },
      },
    ],
    [tasks]
  );

  return (
    <>
      <h3>Task Management</h3>
      <DataTable columns={usercolumns} heading={'My Tasks'} defaultTheme={true} data={tasks||[]}/>
      {/* <div>
        <div
          className="table-container"
          style={{
            padding: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid gray",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontWeight: "bold",
              }}
            >
              Your Tasks
            </h5>
          </div>
          <table>
            <thead
              style={{
                color: "#c0c3c7",
                fontWeight: "bold",
              }}
            >
              <tr
                style={{
                  backgroundColor: "#f1f4f7",
                  fontWeight: "bold",
                }}
              >
                <th>S.No.</th>
                <th>Project Name</th>
                <th>Project Start Date</th>
                <th>Project End Date</th>
                <th>Project Status</th>
                <th>Task Assigned</th>
                <th>Task Start Date</th>
                <th>Task End Date</th>
                <th>Status</th>
              
                <th>Edit Task</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {tasks != "" ? (
                renderTask()
              ) : (
                <tr>
                  <td colSpan="12">"No Task Assigned"</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Stack spacing={2} className="pagination-container">
          <Typography>
            Page: {currentPage} of {totalPages}
          </Typography>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            color="primary"
          />
        </Stack>

        {isLoading && <Loading />}
      </div> */}

      {/* <EditTaskModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        task={currentTask}
      /> */}
    </>
  );
}
