import React, { useEffect, useState } from "react";
import { SelectCustomStyles } from "../../../PageResponsibility/src/utils/styles";
import axios from "../../config/axiosAuth";
import { Button, CircularProgress, TextField } from "@mui/material";
import ReactSelect from "react-select";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../../PageResponsibility/src/admin/components/ui/dialog";
import moment from "moment";
import { useQueryClient } from "react-query";
import { baseUrlUser } from "../../config/baseUrl";
export const styles = {
  Active: {
    color: "green",
    fontWeight: "bold",
  },
  Inactive: {
    color: "red",
    fontWeight: "bold",
  },
  Pending: {
    color: "orange",
    fontWeight: "bold",
  },
};
const Editleave = ({ data }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subject, setSubject] = useState("");
  const [reason, setReason] = useState("");
  const [leaveType, setleaveType] = useState("");
  useEffect(() => {
    console.log(data, "toDatetoDate");
    setStartDate(moment(data?.fromDate).format("YYYY-MM-DD")); // Correct format
    setEndDate(moment(data?.toDate).format("YYYY-MM-DD"));
    setSubject(data?.subject);
    setReason(data?.description);
    setleaveType(data?.leaveType);
  }, []);
  const queryClient = useQueryClient();

  const editLeave = () => {
    try {
      const response = axios.put(baseUrlUser + "/leave", {
        fromDate: startDate,
        toDate: endDate,
        subject: subject,
        leaveType: leaveType,
        description: reason,
        leaveId: data?._id,
        status:'pending'
      });
      queryClient.invalidateQueries("userLeaves");
      setIsEditModalOpen(false)
    } catch (err) {
      throw new Error(err);
    }
  };
  const handleSave = (e) => {
    // e.preventDefault();
    // if (!startDate || !endDate || !subject.trim() || !reason.trim()) {
    //   alert("Please fill all the fields");
    //   return;
    // }
    // const data = {
    //   fromDate: startDate,
    //   toDate: endDate,
    //   subject: subject,
    //   leaveType: leaveType,
    //   description: reason,
    // };
    // applyLeaveMutation.mutate(data);
    // closeEditModal();
    // setStartDate("");
    // setEndDate("");
    // setSubject("");
    // setleaveType("");
    // setReason("");
  };
  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };
  const options = [
    { label: "Second Half", value: "Second Half" },
    { label: "First half", value: "First half" },
    { label: "Full day", value: "Full day" },
  ];
  return (
    <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
      <DialogTrigger>
        <button className="button-17 !rounded-[10px] !text-[10px]">Edit</button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>Edit Leave</DialogHeader>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: new Date().toISOString().slice(0, 10),
            },
          }}
          sx={{
            marginTop: 2,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },
            "& .MuiInputBase-input": {
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
        />

        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: startDate,
            },
          }}
          sx={{
            marginTop: 2,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },
            "& .MuiInputBase-input": {
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
        />
        <div className="mt-[10px]">
          <ReactSelect
            onChange={(option) => {
              setleaveType(option.value);
            }}
            styles={SelectCustomStyles}
            isClearable
            value={options.find((option) => option.value === leaveType) || null}
            options={options}
            placeholder={"Select a type ...."}
          />
        </div>
        <TextField
          label="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          fullWidth
          inputProps={{
            maxLength: 15,
            minLength: 3,
          }}
          sx={{
            marginTop: 2,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },
            "& .MuiInputBase-input": {
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
        />

        <TextField
          label="Reason"
          multiline
          rows={4}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          fullWidth
          sx={{
            marginTop: 2,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.23)",
              },
            },
            "& .MuiInputBase-input": {
              color: "rgba(0, 0, 0, 0.87)",
            },
          }}
        />
        <DialogFooter>
          <Button onClick={closeEditModal}>Cancel</Button>
          <Button onClick={editLeave} variant="contained" color="primary">
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Editleave;
