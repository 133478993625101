import { State } from "country-state-city";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { customStyles } from "../team/Team";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { AddTeamModal } from "../team/TeamModal";
import { AddDesignation } from "../team/Designationmodal";
// import Calendar from 'react-calendar';
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import DatePicker from 'react-modern-calendar-datepicker';
export const AddEmployeeModal = ({ isOpen, closeModal, employeeData }) => {
  // const [validTeams, setValidTeams] = useState();
  const { handleSubmit, control, register, setValue, watch, formState, reset } =
    useForm({
      defaultValues: employeeData,
    });

  const { errors } = formState;

  const { addEmployeeMutation,departmentList ,designationList} = useEmployeeHook();

  const getIndianStates = () => {
    const indianStates = State.getStatesOfCountry("IN");
    return indianStates;
  };

  const getStatesOptions = () => {
    const states = getIndianStates();
    return states.map((state) => (
      <option key={state.isoCode} value={state.name}>
        {state.name}
      </option>
    ));
  };

  const handleNumberInput = (event) => {
    let numericValue = event.target.value.replace(/\D/g, "");

    if (numericValue.length > 0 && numericValue.charAt(0) === "0") {
      numericValue = numericValue.slice(1);
    }

    setValue("number", numericValue.slice(0, 10));
  };

  const validateDateOfBirth = () => {
    const dateOfBirth = watch("dob");
    const dateOfJoining = watch("dateOfJoining");
  
    if (dateOfBirth && dateOfJoining) {
      const dateOfBirthObj = new Date(dateOfBirth);
      const dateOfJoiningObj = new Date(dateOfJoining);
      const today = new Date();
      const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
      
      if (dateOfBirthObj > eighteenYearsAgo) {
        return 'Must be at least 18 years old';
      }else if (dateOfJoiningObj && dateOfBirthObj > dateOfJoiningObj) {
        console.log(dateOfJoiningObj,'dateOfJoiningObj')
        return "Date of Birth cannot be later than Date of Joining";
      }
    }
    return true; // Return true if validation passes
  };

  const onSubmit = (data) => {
    console.log(data,'dataum')
    data.email = data.email.toLowerCase();
    addEmployeeMutation.mutate(data);
    reset();
    closeModal();
  };

  // const getTeams = () => {
  //   axios
  //     .get(baseUrl + "/getAllTeam")
  //     .then((res) => {
  //       setValidTeams(res.data.result);
  //       console.log(res.data.result, "result");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   getTeams();
  // }, []);
  // // const validTeams = [
  //  "backend",
  //  "frontend",
  //  "marketing",
  //  "testing",
  //  "manager",
  //  "design",

  // ];
  const workMode = ["WFH", "HYBRID", "WFO"];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Add Employee Modal"
    >
      <h2>Add Employee</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-group">
            <div className="flex items-center justify-between pr-2"><label htmlFor="employeeId">Department</label> <AddTeamModal className='text-[10px] !bg-transparent !border-none w-max-content text-grey hover:bg-transparent !p-0 h-max-content !h-[10px]'/></div>
            <select
              id="teamId"
              value={watch("teamId")}
              onChange={(e) => setValue("teamId", e.target.value)}
              {...register("teamId", {
                required: "Department is required",
              })}
            >
              <option value="">Select Department</option>
              <div value=''> </div>
              {departmentList && departmentList?.length > 0
                ? departmentList?.map((rowdata, index) => (
                    <option key={rowdata} value={rowdata._id}>
                      {rowdata.teamName}
                    </option>
                  ))
                : null}
            </select>
            {errors.teamId && (
              <p className="error-message">{errors.teamId.message}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="employeeId">Work Mode</label>
            <select
              id="workMode"
              value={watch("workMode")}
              onChange={(e) => setValue("workMode", e.target.value)}
              {...register("workMode", {
                required: "WorkMode is required",
              })}
            >
              <option value="">Select workMode</option>
              {workMode && workMode?.length > 0
                ? workMode?.map((rowdata, index) => (
                    <option key={rowdata} value={rowdata}>
                      {rowdata}
                    </option>
                  ))
                : null}
            </select>
          {errors.workMode && (
              <p className="error-message">{errors.workMode.message}</p>
            )}
          </div>
        </div>
        <div className="form-row">
        
          <div className="form-group" >
          <div className="flex items-center justify-between pr-2">  <label htmlFor="Designation">Designation</label> <AddDesignation className='text-[10px] bg-transparent border-none w-max-content text-grey hover:bg-transparent p-0 h-max-content !h-[10px]'/></div>
          
          
            <select
              id="Designation"
              value={watch("designation")}
              onChange={(e) => setValue("designation", e.target.value)}
              {...register("designation", {
                required: "This field is required",
              })}
            >
              <option value="">Select Designation</option>
              <div value=''> </div>
              {designationList && designationList?.length > 0
                ? designationList?.map((rowdata, index) => (
                    <option key={rowdata._id} value={rowdata._id}>
                      {rowdata.name}
                    </option>
                  ))
                : null}
            </select>
            {errors.designation && (
              <p className="error-message">{errors.designation.message}</p>
            )}
           
          </div>
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="designation">Role:</label>
            <input
              type="text"
              id="role"
              name="role"
              {...register("role", {
                required: {
                  value: true,
                  message: "Role is required",
                },
                maxLength: {
                  value: 25,
                  message: "Maximum 25 characters allowed",
                },
                minLength: {
                  value: 5,

                  message: "Minimum 5 characters required",
                },
              })}
            />
            {errors.role && (
              <p className="error-message">{errors.role.message}</p>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="employeeId">Employee ID:</label>
            <input
              type="text"
              id="employeeId"
              name="employeeId"
              {...register("employeeId", {
                required: "Employee ID is required",
                pattern: {
                  value: /^[a-zA-Z0-9]+$/,
                  message:
                    "Only alphanumeric values are allowed, and no white spaces are allowed",
                },

                maxLength: {
                  value: 20,
                  message: "Maximum 20 characters allowed",
                },
                minLength: {
                  value: 5,
                  message: "Minimum 5 characters required",
                },
              })}
            />
            {errors.employeeId && (
              <p className="error-message">{errors.employeeId.message}</p>
            )}
          </div>
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              {...register("name", {
                required: "Name is required",
                validate: {
                  isNotNumeric: (value) =>
                    isNaN(value) || "Name cannot be a number",
                },
                maxLength: {
                  value: 30,
                  message: "Maximum 30 characters allowed",
                },
                minLength: {
                  value: 3,
                  message: "Minimum 3 characters required",
                },
              })}
            />
            {errors.name && (
              <p className="error-message">{errors.name.message}</p>
            )}
          </div>
        </div>
        <div className="form-row">
        <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="gender">Gender:</label>
            <select
              id="gender"
              {...register("gender", { required: "Gender is required" })}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {errors.gender && (
              <p className="error-message">{errors.gender.message}</p>
            )}
          </div>
         
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="dateOfJoining">Date of Joining:</label>
            <input
              type="date"
              id="dateOfJoining"
              name="dateOfJoining"
              {...register("dateOfJoining", {
                required: "Date of Joining is required",
              })}
              max={new Date().toISOString().split("T")[0]}
            />
            {errors.dateOfJoining && (
              <p className="error-message">{errors.dateOfJoining.message}</p>
            )}
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="dob">Date of Birth:</label>
            <input
              type="date"
              id="dob"
              name="dob"
              {...register("dob", {
                required: "Date of Birth is required",
                // validate: validateDateOfBirth,
                validate: {
                  isAtLeast18: (value) => {
                    const dateOfBirth = new Date(value);
                    const today = new Date();
                    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
                    return dateOfBirth <= eighteenYearsAgo || 'Must be at least 18 years old';
                  },
                  isBeforeJoining: (value) => {
                    const dateOfBirth = new Date(value);
                    const dateOfJoining = watch("dateOfJoining");
        
                    // Only run the validation if a date of joining is selected
                    if (dateOfJoining) {
                      const joiningDate = new Date(dateOfJoining);
                      return (
                        dateOfBirth <= joiningDate ||
                        "Date of Birth cannot be later than Date of Joining"
                      );
                    }
                    return true; // No error if dateOfJoining is not selected
                  },
                },
              })}
              max={new Date().toISOString().split("T")[0]}
            />
           {/* <Controller
              name="dob"
              control={control}
              rules={{ validate: validateDateOfBirth }}
              render={({ field }) => (
                <DatePicker
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  renderInput={renderCustomInput}
                  inputPlaceholder="Select Date of Birth"
                  maximumDate={{
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + 1,
                    day: new Date().getDate(),
                  }}
                  shouldHighlightWeekends
                />
              )}
            /> */}
            {errors.dob && (
              <p className="error-message">{errors.dob.message}</p>
            )}
          </div>
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="number">Contact Number:</label>
            <input
              type="text"
              id="number"
              name="number"
              {...register("number", {
                required: {
                  value: true,
                  message: "Contact Number is required",
                },

                maxLength: {
                  value: 10,
                  message: "Contact Number should not exceed 10 digits",
                },
                minLength: {
                  value: 10,
                  message: "Contact Number should be 10 digits",
                },
              })}
              onInput={handleNumberInput}
            />
            {errors.number && (
              <p className="error-message">{errors.number.message}</p>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              id="email"
              name="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Please enter a valid email",
                },

                maxLength: {
                  value: 50,
                  message: "Maximum 50 characters allowed",
                },
              })}
            />
            {errors.email && (
              <p className="error-message">{errors.email.message}</p>
            )}
          </div>
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="state">State:</label>
            <select
              id="state"
              name="state"
              {...register("state", { required: "State is required" })}
            >
              <option value="">Select a state</option>
              {getStatesOptions()}
            </select>
            {errors.state && (
              <p className="error-message">{errors.state.message}</p>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="salaryPerMonth">Salary Per Month:</label>
            <input
              type="number"
              id="salaryPerMonth"
              name="salaryPerMonth"
              {...register("salaryPerMonth", {
                // required: "Salary Per Month is required",
                // validate: {
                //   isNotNegative: (value) =>
                //     value >= 0 || "Salary cannot be negative",
                //   isZero: (value) => value > 0 || "Salary cannot be zero",
                // },
              })}
            />
            {errors.salaryPerMonth && (
              <p className="error-message">{errors.salaryPerMonth.message}</p>
            )}
          </div>
          <div className="form-group" style={{ width: "45%" }}>
            <label htmlFor="password">Password:</label>
            <input
              type="text"
              id="password"
              name="password"
              {...register("password", {
                required: "Password is required",
                pattern: {
                  value:
                    // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                    /^(?!.*\s)(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,

                  message:
                    "Password must be at least 8 characters long and contain at least one letter and one number one special character and no spaces",
                },
                maxLength: {
                  value: 20,
                  message: "Maximum 20 characters allowed",
                },
              })}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault(); 
                }}}
            />
            {errors.password && (
              <p className="error-message">{errors.password.message}</p>
            )}
          </div>
        </div>
        <div>
          <button
            type="submit"
            style={{
              borderRadius: "5px",
              width: "10vw",
              backgroundColor: "#085394",
              color: "white",
            }}
          >
            Add Employee
          </button>
        </div>
      </form>
    </Modal>
  );
};
