import React from 'react'
import { useLocation } from 'react-router-dom'
import { DataTable } from '../../../PageResponsibility/src/admin/components/table/Data-Table'
import { DescriptionModal } from '../../../PageResponsibility/src/admin/components/modals/DiscriptionModal'

const ViewReport = () => {
    const location= useLocation()
    console.log(location,'locatiooooooooooooooooooooon')

 const columns = [
        {
          header: "S.No",
          cell: ({ row }) => {
            return <span>{row.index + 1}</span>;
          },
        },
      
        {
          header: "Project",
          cell: ({ row }) => {
            return <span>{row.original.project?.projectName}</span>;
          },
        },
      
        {
          header: "Report",
      cell:({row})=>row.original?.report?row.original?.report?.length>14? <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row.original?.report.substring(0,14)}....`}</span>} data={row.original?.report}/>:row.original?.report:'-'

          // cell: ({ row }) => {
          //   return<DescriptionModal  data={row.original?.report} />;
          // },
        },
      
       
      ];
  return (
<DataTable data={location?.state?.data||[]} columns={columns} heading='Report ' defaultTheme={true}/>
  )    
}

export default ViewReport
