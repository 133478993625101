import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MeetingsList.css";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";

import { baseUrl, baseUrlUser } from "../../config/baseUrl";
import moment from "moment";

const MeetingsList = () => {
  const [meeting, setMeeting] = useState();

  const handleAllmeeting = () => {
    axios
      .get( baseUrlUser+ "/all-meetings")
      .then((res) => {
        console.log(res.data,'meete');
        setMeeting(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };
const navigate=useNavigate()
  useEffect(() => {
    handleAllmeeting();
  }, []);
  

  const JoinMeetingClick=(id)=>{
    const data={linkId:id.linkId,
      id:id._id
    }
   navigate(`/userMeet/${id.linkId}`,{
    state: data
    
  })
}
const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    accessorKey: "title",
    header: "Title",
  },

  {
    accessorKey: "date",
    Header: "Date-Time",
    cell:({row})=>moment.utc(row?.original?.date).format('DD-MM-YYYY HH:mm')
  },

  {
    accessorKey: "description",
    header: "Description",
  },
  {
    header: "Meeting",
    cell: ({ row }) => {
      return (
        <span
          style={{
            width: "10vw",
            padding:"6px",
            background: "#085394e6",
            color: "white",
            borderRadius: "5px",
            cursor:"pointer"
          }}
          onClick={()=>{return(
            JoinMeetingClick(row.original),
            console.log('iamclicked',row.original.linkId))}}
        >
          Join Meeting
        </span>
      );
    },
  },
];

  return (
    <div>
          {/* <div style={{display:"flex",justifyContent:"flex-end"}}>
        <button type="btn" className="join_meeting_btn">Join Meeting</button>
      </div> */}
      <div>
        <DataTable columns={columns} data={meeting} defaultTheme={true} heading='Meetings'/>
      </div>

    
    </div>
  );
};

export default MeetingsList;
