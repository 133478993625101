import axios from "../config/axiosAuth";

import { authHeaders } from "../config/apiConfig";
import { baseUrlUser } from "../config/baseUrl";

const userService = {
  getUserLeaves: async (fetch) => {
    let url ;
    console.group(fetch,'llllllll in service')
    if(fetch&&fetch.all){
      url=`${baseUrlUser}/allLeaves?all=${true}`
    }else{
      url=`${baseUrlUser}/allLeaves`
    }
    const response = await axios.get(url, {
      headers: authHeaders,
    });
    return response.data;
  },
  getUserApprovedLeaves: async () => {
    const response = await axios.get(`${baseUrlUser}/approvedLeaves`, {
      headers: authHeaders,
    });
    return response.data.result;
  },
  updateProfile:async(image)=>{
    const response =await axios.put(`${baseUrlUser}/profile`,image)
    return response.data;
  },
  getApprovedUserLeaves: async () => {
    const response = await axios.get(`${baseUrlUser}/allLeaves`, {
      headers: authHeaders,
      params: {
        status: "approved",
      },
    });
    return response.data;
  },

  getUserDetails: async () => {
    const response = await axios.get(`${baseUrlUser}/getEmployee`);
    return response?.data;
  },

  getUserTotalProjects: async () => {
    const response = await axios.get(`${baseUrlUser}/getProject`, {
      headers: authHeaders,
    });
    console.log(response,'getUserTotalProjects')
    return response?.data;
  },

  getUserCompletedProjects: async () => {
    const response = await axios.get(`${baseUrlUser}/getProject`, {
      headers: authHeaders,

      params: {
        status: "Completed",
      },
    });
    return response?.data;
  },

  getUserPendingProjects: async () => {
    const response = await axios.get(`${baseUrlUser}/getProject`, {
      headers: authHeaders,

      params: {
        status: "InProgress",
      },
    });
    return response?.data;
  },

  editProjectStatus: async (id, status) => {
    const response = await axios.put(
      `${baseUrlUser}/update/${id}`,
      { status: status },
      {
        headers: authHeaders,
      }
    );
    return response?.data;
  },
  updateDeviceToken:async(token)=>{
    const response=await axios.put(`${baseUrlUser}/deviceToken`,
      {deviceToken:token},
      {
        headers: authHeaders,
      }
    )
    return response;
  },

  applyLeave: async (data) => {
    const res = await axios.post(baseUrlUser + "/createLeave", data, {
      headers: authHeaders,
    });
    return res;
  },
};

export { userService };
