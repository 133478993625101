import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Modal from "react-modal";
import useUserHook from "../../hooks/userHook";
import useReportHook from "../../hooks/ReportHook";
import { Button } from "../../components/ui/button";
import ReactSelect from "react-select";
import { Trash } from "lucide-react";
import useUserProjectStore from "../../store/userProject";
import { useState } from "react";
import { baseUrlUser } from "../../config/baseUrl";
import axios from '../../config/axiosAuth'

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    backgroundColor: "#fff",
  },
};
export function AddTeamReportUser({ isOpen, isClose }) {
  const [projectsList, setProjectsList] = useState([]);
  const [teamMembers,setTeamMebers]=useState([])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "reports",
    });
  const { addTeamReportUserMutation } = useReportHook();
  const { projects } = useUserProjectStore();
  const[error,setError]=useState('')

  useEffect(() => {
    const options = projects?.map((item) => ({
      label: item.projectName,
      value: item._id,
    }));
    setProjectsList(options);
  }, [projects]);

  const addReport = (data) => {
    console.log(data, "report data");
    if (data?.reports?.length > 0) {
      addTeamReportUserMutation.mutate(data?.reports);
    }else{
      setError('Please Create a Report')
    }
  };
  useEffect(()=>{

    console.log(teamMembers,'datataaa')
  },[teamMembers])

  const getTeamMebers =async (teamId,index) => {
    try {
      const response =await axios.get(baseUrlUser + "/my-team");

      setTeamMebers((prev)=>(
       
      response?.data?.data?.map((item)=>({ value: item._id, label: item.name }))
      ))
    } catch (err) {
      throw new Error(err);
    }
  };
  useEffect(()=>{
    getTeamMebers()
  },[])

  const AddReport=()=>{

  }
  return (
    
    <>
      <h2>
        <span
          style={{
            color: "#085394",
          }}
        >
          Add Report
        </span>
        <span
          style={{
            float: "right",
            cursor: "pointer",
          }}
          onClick={()=>setError('')}
        >
          X
        </span>
      </h2>
      <div className="modal-container">
        <div className="modal-body">
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            onSubmit={handleSubmit(addReport)}
          >
            {/* <textarea
              rows="10"
              cols="50"
              name="report"
              id="report"
              {...register("report", {
                required: "Please enter your daily report",
              })}
            />

            {errors.report && (
              <span className="error">{errors.report.message}</span>
            )} */}
            {error&&<p className="error-message">{error}</p>}
            <ul>
              {fields.map((item, index) => {
                return (
                  <li key={item.id}>
                    <div className="form-row">
                      <div className="form-group">
                        <Controller
                          rules={{
                            required: "Please select a Employee",
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              onChange={(val) => {
                                return field.onChange(val ? val.value : null);
                              }}
                              // styles={style}
                              isClearable
                              // value={
                              //   teamMembers?.[index]?.find(
                              //     (option) => option.value === field.value
                              //   ) || null
                              // }
                              options={teamMembers}
                              placeholder={"Select a Employee ...."}
                            />
                          )}
                          name={`reports.${index}.createdFor`}
                          control={control}
                        />
                        {errors?.reports?.[index]?.createdFor?.message && (
                          <p className="error-message">
                            {" "}
                            {errors?.reports?.[index]?.createdFor?.message}
                          </p>
                        )}
                      </div>
                      <textarea
                        rows="10"
                        cols="50"
                        name={`reports.${index}.report`}
                        id="report"
                        {...register(`reports.${index}.report`, {
                          required: "Please enter your daily report",
                        })}
                      />

                      {errors?.reports?.[index]?.report?.message && (
                        <p className="error-message">
                          {" "}
                          {errors?.reports?.[index]?.report?.message}
                        </p>
                      )}
                    </div>
                    <button type="button" onClick={() => remove(index)}>
                      <Trash color={"red"} />
                    </button>
                  </li>
                );
              })}
            </ul>
            <hr />
            <button
              type="button"
              onClick={() => append({})}
              className="flex items-center justify-center mt-[10px] gap-2 mb-[30px]"
            >
              <div
                className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]"
                style={{ backgroundColor: "rgb(8,83,148,0.3)" }}
              >
                +
              </div>
              Project Report
            </button>
            <div>
              <Button className="btn btn-primary" type="submit">
                Add Report
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
    // </Modal>
  );
}
