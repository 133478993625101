import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "../../components/ui/dialog.jsx";

import { State } from "country-state-city";

import { Button } from "../../components/ui/button.jsx";
import { AddIcon } from "../../components/ui/icons.jsx";
import { baseUrl } from "../../config/baseUrl.js";
import axios from "../../config/axiosAuth.js";
import useEmployeeHook from "../../hooks/useEmployeeHook.jsx";
import { useQueryClient } from "react-query";

export const AddTask = () => {
  const [projectData, setProjectData] = useState();
  const [departmentData, setDepartmentData] = useState();
  const [employeeData, setEmployeeData] = useState();
  const queryClient = useQueryClient();
  const [status, setStatus] = useState([
    "Pending",
    "Completed",
    "Approved",
    "In Progress",
  ]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    formState,
    reset,
  } = useForm();
  const[open,setOpen]=useState(false)

  const getIndianStates = () => {
    const indianStates = State.getStatesOfCountry("IN");
    return indianStates;
  };

  const getStatesOptions = () => {
    const states = getIndianStates();
    return states.map((state) => (
      <option key={state.isoCode} value={state.name}>
        {state.name}
      </option>
    ));
  };
  const id = watch("ProjectId");
  const teamName = watch("departmentId");

  const { errors } = formState;

  const onSubmit = (data) => {
    axios
      .post(baseUrl + "/addTask", {
        task: data.task,
        employeeId: data.EmployeeId,
        projectId: data.ProjectId,
        status: data.status,
        start_date: data.startDate,
        end_date: data.endDate,
        description: data.Description,
        department: data.departmentId
        // teamName:data.departmentId
      })

      .then((res) => {
        queryClient.invalidateQueries("taskManagement");
        setOpen(false)
      })

      .catch((err) => {
      });
  };
  const validateDate = () => {
    const startDate = new Date(watch("startDate"));
    const endDate = new Date(watch("endDate"));

    if (startDate > endDate) {
      return "Start date must be lower than end date";
    }
  };
  useEffect(() => {
    handleprojectId();
  }, []);
  const ids = watch("ProjectId");
  const handleprojectId = () => {
    axios
      .get(baseUrl + "/allProjects", {})

      .then((res) => {
        setProjectData(res.data.result.project);
      })

      .catch((err) => {
      });
  };
  const handleAddtask = () => {};
  useEffect(() => {
    if (watch("ProjectId")!==''&&watch("ProjectId")!==undefined&&teamName!==''&&teamName!==undefined) {
      axios
        .post(baseUrl + "/projectTeamEmployees", {
          projectId: id,
          teamName: teamName,
        })

        .then((res) => {
          setEmployeeData(res.data.employees);
        })

        .catch((err) => {
        });
    }
  }, [teamName, id]);

  const getDepartmentsByproject = async () => {

    try {
      if(watch("ProjectId")!==''&&watch("ProjectId")!==undefined){

        const response = await axios.post(baseUrl + "/department-by-project", {
          projectId: watch("ProjectId"),
        });
        setDepartmentData(response.data.data.teams);
      }
    } catch (err) {
      throw new Error(err);
    }
  };
  useEffect(() => {
    if (id !== "") {
      try {
        getDepartmentsByproject();
      } catch (err) {
        throw new Error(err);
      }
    }
  }, [id]);
  return (
    <Dialog open={open} 
    onOpenChange={(open) => {
      setOpen(open);
      if (!open) {
        setEmployeeData([])
        reset();
      }}}>
      <DialogTrigger className="flex text-sm items-center gap-2 bg-[#085394] min-w-[120px] p-2 rounded-lg ml-auto">
        <span> Add Task</span>
        <AddIcon />
      </DialogTrigger>
      <DialogContent>
        <div>
          <h2>Add Task</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="companyName">Project Name</label>
                <select
                  id="ProjectId"
                  value={watch("ProjectId")}
                  onChange={(e) => setValue("ProjectId", e.target.value)}
                  {...register("ProjectId", {
                    required: "Project is required",
                  })}
                >
                  <option value="">Select Your Project</option>

                  {projectData && projectData?.length > 0
                    ? projectData?.map((rowdata, index) => (
                        <>
                          <option key={rowdata._id} value={rowdata._id}>
                            {rowdata.projectName}
                          </option>
                        </>
                      ))
                    : null}
                </select>

                {errors.ProjectId && (
                  <span className="error-message">
                    {errors.ProjectId.message}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="companyName">Department</label>
                <select
                  id="departmentId"
                  value={watch("departmentId")}
                  onChange={(e) => setValue("departmentId", e.target.value)}
                  {...register("departmentId", {
                    required: "Department is required",
                  })}
                >
                  <option value="">Select Department</option>
                  {departmentData && departmentData?.length > 0
                    ? departmentData?.map((rowdata, index) => (
                        <>
                          <option key={rowdata.teamType?._id} value={rowdata.teamType?._id}>
                            {rowdata.teamType.teamName}
                          </option>
                        </>
                      ))
                    : <option value="">NO Results Found</option>}
                </select>

                {errors.departmentId && (
                  <span className="error-message">
                    {errors.departmentId.message}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="companyName">Employee</label>
                <select
                  id="EmployeeId"
                  value={watch("EmployeeId")}
                  onChange={(e) => setValue("EmployeeId", e.target.value)}
                  {...register("EmployeeId", {
                    required: "Employee is required",
                  })}
                >
                   <option value="">Select Employee</option>

                  {employeeData && employeeData?.length > 0 ? (
                    employeeData?.map((rowdata, index) => (
                      <option key={rowdata._id} value={rowdata._id}>
                        {rowdata?.name}
                      </option>
                    ))
                  ) : (
                    <option value=''>No results found</option>
                  )}
                </select>
                {/* <input
                  type="text"
                  {...register("EmployeeId", {
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",

                    required: {
                      value: true,
                      message: "Employee Id is required",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9\s]*$/,
                      message: "Only alphabets ,numbers are allowed",
                    },
                    maxLength: {
                      value: 15,
                      message: "Maximum 15 characters are allowed",
                    },

                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are allowed",
                    },
                  })}
                /> */}
                {errors.EmployeeId && (
                  <span className="error-message">
                    {errors.EmployeeId.message}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="mobileNumber">Status Of Task</label>
                <select
                  id="status"
                  value={watch("status")}
                  onChange={(e) => setValue("status", e.target.value)}
                  {...register("status", {
                    required: "Status is required",
                  })}
                >
                  <option value=''>Select Your Task</option>

                  {status && status?.length > 0
                    ? status?.map((rowdata, index) => (
                        <option key={rowdata} value={rowdata}>
                          {rowdata}
                        </option>
                      ))
                    : null}
                </select>
                {/* <input
                  type="text"
                  maxLength="10"
                  {...register("Status", {
                    validate: (value) => value !== "Status is required",

                    required: {
                      value: true,
                      message: "Status is required",
                    },
                  })}
                /> */}
                {errors.status && (
                  <span className="error-message">{errors.status.message}</span>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  {...register("startDate", {
                    required: {
                      value: true,
                      message: "Start date is required",
                    },
                  })}
                />
                {errors.startDate && (
                  <span className="error-message">
                    {errors.startDate.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  min={watch("startDate")}
                  {...register("endDate", {
                    required: {
                      value: true,
                      message: "End date is required",
                    },
                    validate: validateDate,
                  })}
                />
                {errors.endDate && (
                  <span className="error-message">
                    {errors.endDate.message}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="amount">Description</label>
                <input
                  type="text"
                  {...register("Description", {
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",

                    required: {
                      value: true,
                      message: "Description is required",
                    },
                    // pattern: {
                    //   value: /^[0-9]*$/,
                    //   message: "Only numbers are allowed",
                    // },
                    maxLength: {
                      value: 30,
                      message: "Maximum 30a characters are allowed",
                    },

                    minLength: {
                      value: 1,
                      message: "Minimum 1 characters are allowed",
                    },
                  })}
                />
                {errors.Description && (
                  <span className="error-message">
                    {errors.Description.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="amount">Task</label>
                <input
                  type="text"
                  {...register("task", {
                    validate: (value) =>
                      value.trim() !== "" || "Input cannot be only whitespaces",

                    required: {
                      value: true,
                      message: "Task is required",
                    },
                    // pattern: {
                    //   value: /^[0-9]*$/,
                    //   message: "Only numbers are allowed",
                    // },
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed",
                    },

                    minLength: {
                      value: 1,
                      message: "Minimum 1 characters are allowed",
                    },
                  })}
                />
                {errors.task && (
                  <span className="error-message">{errors.task.message}</span>
                )}
              </div>
            </div>
            <DialogFooter>
              {formState.isValid && (
                // <DialogClose asChild>
                  <Button type="submit">Save</Button>
                // </DialogClose>
              )}

              {!formState.isValid && (
                <Button type="submit" onClick={handleAddtask}>
                  Save
                </Button>
              )}

              <DialogClose asChild>
                <Button onClick={() => reset()}>Close</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
